/* Settings */
:root {
  --gradientFirst: #f6f7f8;
  --gradientSecond: #e9eef2;
  --linkPrimary: #159ce4;
  --linkPrimary--hover: #147ec1;
  --textColor: #597183;
  --titleColor: #32383c;
}

html {
  font-size: 16px;
}

body {
  background-image: radial-gradient(
    var(--gradientFirst),
    var(--gradientSecond)
  );
  color: var(--textColor);
  font-family: 'Alice', serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
}

a {
  color: var(--linkPrimary);
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

a:hover {
  color: var(--linkPrimary--hover);
  text-decoration: underline;
}

.container {
  width: 100%;
  max-width: 750px;
  padding: 5%;
  margin: 0 auto;
  box-sizing: border-box;
  text-align: center;
}

.content {
  margin-bottom: 20px;
  padding: 0;
}

.title {
  color: var(--titleColor);
  font-size: 3rem;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: -1px;
  text-align: center;
}

.headline {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: -1px;
  text-align: center;
}

.social-list {
  margin: 0 auto;
  padding: 0;
  text-align: center;
}

.social-list li {
  display: inline-block;
  list-style: none;
  margin: 25px;
  padding: 0;
}

.social-icon {
  transition: transform 0.3s;
}

.social-icon:hover {
  transform: scale(1.3);
}
